import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ExternalService {

    constructor(private http: HttpClient, private route: ActivatedRoute) { }

    public Login;
    phoneNo: string;
    test: any;
    public NewCustomer;
    public CustomerOTP;
    public OldCustomerLg;
    public CustAddress;
    public SaveCustAddress



    private showHeader = true;

    setShowHeader(show: boolean) {
        this.showHeader = show;
    }

    getShowHeader(): boolean {
        return this.showHeader;
    }

    // Get Check Customer

    public GetPhoneno(phone) {

        this.test = phone
        const cid = 12
        // console.log('______', this.test);

        const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/customerLoginFB?mobile=' + this.test + '&cid=' + 12;
        this.Login = this.http.get(apiUrl);
        return this.Login;
    }

    public get _getlogin(): Observable<ExternalService[]> {
        return this.Login;
    }



    // ADD NEW CUSTOMER

    public GetNewCust(datacustomer) {
        // console.log(datacustomer)
        const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/NcustomerLoginFB';
        this.NewCustomer = this.http.post(apiUrl, datacustomer);
        return this.NewCustomer;
    }

    public get _getcustomerAdd(): Observable<ExternalService[]> {
        return this.NewCustomer;
    }



    // OTP VERIFICATION


    public GetCustomerOtp(cusId, cusotp, cid, body) {
        // console.log(cusId, cusotp);

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/otpverifyFB?custid=${cusId}&otp=${cusotp}&cid=${cid}`;

        this.CustomerOTP = this.http.post(apiUrl, body);
        return this.CustomerOTP;
    }

    public get _getCustomerOtp(): Observable<ExternalService[]> {
        return this.CustomerOTP;
    }


    // OLD CUSTOMER LOGIN

    public GetOldCustomer(cusId, mobile, body) {
        // console.log(cusId, mobile, body);

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/EcustomerLoginFB?custid=${cusId}&mobile=${mobile}`;

        this.OldCustomerLg = this.http.post(apiUrl, body);
        return this.OldCustomerLg;

    }

    public get _getoldCustomer(): Observable<ExternalService[]> {
        return this.OldCustomerLg;
    }
    // this.customerid = localStorage.getItem('customerid');
    // console.log('customerid',this.customerid);


    // CHECK OUT
    public customerid
    public secid

    public GetCustAddress(customerid, secid) {

        //    this.customerid = customerid
        // console.log(customerid);

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/getCustAddress?custid=` + customerid + '&secid=' + secid;

        this.CustAddress = this.http.get(apiUrl);
        return this.CustAddress;

    }

    public get _getCustAddress(): Observable<ExternalService[]> {
        return this.CustAddress;
    }



    public SaveCustomerAddress(body) {

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/saveCusAddress`;

        this.SaveCustAddress = this.http.post(apiUrl, body);
        return this.SaveCustAddress;

    }

    public get _getSaveCustAddress(): Observable<ExternalService[]> {
        return this.SaveCustAddress;
    }


    public SelectCustAddress


    public SelectCustomerAddress(customerid, custadresid) {
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/changecurrAddress?cudtid=` + customerid + '&cusAddtId=' + custadresid;

        this.SelectCustAddress = this.http.post(apiUrl, customerid, custadresid);
        return this.SelectCustAddress;

    }

    public get _getselectcustomerAddress(): Observable<ExternalService[]> {
        return this.SelectCustAddress;
    }



    public CartDELData: any

    public DeleteCart(Id) {
        // console.log(Id);

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/removeCart?cartid=${Id}`;

        this.CartDELData = this.http.post(apiUrl, Id);
        return this.CartDELData;

    }

    public get _getCartDel(): Observable<ExternalService[]> {
        return this.CartDELData;
    }

    public Customerdelete: any

    public DeleteCustomer(custid, custaddressid) {
        // console.log(custid, custaddressid);

        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/removeCustAddress?custid=` + custid + '&custaddressid=' + custaddressid;

        this.Customerdelete = this.http.post(apiUrl, custid, custaddressid);
        return this.Customerdelete;

    }

    public get _getcusDel(): Observable<ExternalService[]> {
        return this.Customerdelete;
    }

    public QtyUpdate
    public UpdateQty(body) {
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/updateCart`;
        this.QtyUpdate = this.http.post(apiUrl, body);
        return this.QtyUpdate;

    }

    public get _getUpdateQty(): Observable<ExternalService[]> {
        return this.QtyUpdate;
    }

    public UpdateCus
    public UpdatecusAdd(body) {
        // console.log(body);
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/editCustAddress`;
        this.UpdateCus = this.http.post(apiUrl, body);
        return this.UpdateCus;

    }

    public get _getUpdateCus(): Observable<ExternalService[]> {
        return this.UpdateCus;
    }
    public SubscribeMail
    public Subscribed(body) {
        console.log(body);
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/custsub`;
        this.SubscribeMail = this.http.post(apiUrl, body);
        return this.SubscribeMail;

    }

    public get _getSubscribeMail(): Observable<ExternalService[]> {
        return this.SubscribeMail;
    }

    public Allstores

    public GetAllstores() {
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/getAllStores`;
        this.Allstores = this.http.get(apiUrl);
        return this.Allstores;

    }


    //Get Reviews -----------------------------------------------------------------------------------------


    public Reviews

    public GetReviews(pId, oid, cid) {
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/getreview?pid=` + pId + '&oid=' + oid + '&cid=' + cid;
        this.Reviews = this.http.get(apiUrl);
        return this.Reviews;

    }

    toFormData(formValue) {
        // console.log(formValue)
        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            // console.log(value)
            formData.append(key, value);
        }
        return formData;
    }


    public AddReview
    public addReview(img1, img2, body) {
        var body1 = new FormData();
        body1 = this.toFormData(body)
        // body1.append('payload', body.payload);
        body1.append("image1", img1);
        body1.append("image2", img2);
        // console.log(body1)
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/savereview`;
        this.AddReview = this.http.post(apiUrl, body1);
        return this.AddReview;

    }


    public AddContact
    public AddContactEnq(body) {
        // console.log(body);

        // console.log(body1)
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/ECom/SaveContactUs`;
        this.AddContact = this.http.post(apiUrl, body);
        return this.AddContact;

    }

    // saveEmployee(empImgFile: File) {
    //     const formData: FormData = new FormData();

    //     if (empImgFile) {
    //         formData.append('EmpImgFile', empImgFile, empImgFile.name);
    //     }
    //     return this.http.post(${ this.base_url_0 }ERP / SaveEmployee, formData);
    // }


    // public get _getreview(): Observable<[]> {
    //     return this.AddReview;
    // }

    // Cancel-oRderrrrrr--------------------------------------------


    public CancelUpdate
    public Cancelord(body) {
        // console.log(body);
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/home/ordCancelreq`;
        this.CancelUpdate = this.http.post(apiUrl, body);
        return this.CancelUpdate;

    }

    public AllProdImg
    public AllproductsGallery(): Observable<ExternalService[]> {
        const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getgallary?cid=12';
        this.AllProdImg = this.http.get<ExternalService[]>(apiUrl);
        return this.AllProdImg;
    }

    public get getAllProductsImg(): Observable<ExternalService[]> {
        return this.AllproductsGallery();
    }


    // Coupons get-----------------------------------------------------------------------------------
    public Coupons
    public getCoupons(id) {
        const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getecomcoopens?custid=' + id;
        this.Coupons = this.http.get(apiUrl);
        return this.Coupons;
    }

    public Couponscheck
    public GetCouponscheck(body) {
        // console.log(body);
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/home/coopenverify`;
        this.Couponscheck = this.http.post(apiUrl, body);
        return this.Couponscheck;

    }
    public get _GetCouponscheck(): Observable<ExternalService[]> {
        return this.Couponscheck;
    }

    // Scratch0----------------------------------

    public scratchcard
    public Getscratchcard(id) {
        // console.log(id);
        const apiUrl = `https://uat-ecom.azurewebsites.net/api/home/setScratched?id=` + id;
        this.scratchcard = this.http.post(apiUrl, id);
        return this.scratchcard;

    }
    public get _Getscratchcard(): Observable<ExternalService[]> {
        return this.scratchcard;
    }

}
import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { HomepageModule } from '../../classes/product';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() breadcrumb: string;
  public HomePage: HomepageModule[] = [];
  private footerSubscription!: Subscription;
  public footer1 = []
  constructor(public productService: ProductService, private router: Router,) {
    // if (this.router.url.split('/')[1] === 'pages/login') {
    //   this.productService.Footerget.subscribe(x => {
    //     this.HomePage = x
    //     this.footer1 = this.HomePage["footer"]

    //     this.footer1.forEach(ele=>{
    //       ele.Sangeetha = "hi"
    //     })
    //     console.log(this.footer1)

    //   })
    // }

    if (this.router.url === '/pages/login') {
      this.footerSubscription = this.productService.Footerget.subscribe(x => {
        this.HomePage = x;
        this.footer1 = this.HomePage["footer"];
        // this.footer1.forEach(ele=>{
        //   ele.Sangeetha = "hi"
        // })
        console.log(this.footer1);
      });
    }
  }

  ngOnDestroy() {
    if (this.footerSubscription) {
      this.footerSubscription.unsubscribe();
    }
  }

  ngOnInit(): void { }

}

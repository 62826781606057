<div class="chat-circle-chat hidemobileview" *ngIf="chathide == true">
  <section class="msger" style="float: inline-end;padding: 0;">
    <header class="msger-header">
      <div class="msger-header-title">
        <i class="fas fa-comment-alt"></i> Customer Support
      </div>
      <div class="msger-header-options" (click)=" closechat() ">
        <span><i class="fa fa-times" aria-hidden="true"></i></span>
      </div>
    </header>

    <main class="msger-chat">
      <div class="msg left-msg">
        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">Customer Care</div>
            <div class="msg-info-time">12:45</div>
          </div>

          <div class="msg-text">
            Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
          </div>
        </div>


      </div>





      <!-- chatttttttttttt--------dataaaaaaaaaaaaaaaaaaaaa -->
      <div *ngFor="let data of DataChat">


        <div class="msg right-msg">
          <div class="msg-bubble">
            <div class="msg-info">
              <div class="msg-info-name">{{CusName}}</div>
              <div class="msg-info-time">{{data.sendTime}}</div>
            </div>

            <div class="msg-text">
              {{data.sender}}
            </div>
          </div>
        </div>

        <div class="msg left-msg" style="margin-top: 10px;">


          <div class="msg-bubble">
            <div class="msg-info">
              <div class="msg-info-name">Customer Care</div>
              <div class="msg-info-time">{{data.receiveTime}}</div>
            </div>

            <div class="msg-text">
              {{data.receiver}}
            </div>
          </div>

        </div>
      </div>

    </main>

    <form class="msger-inputarea">
      <input type="text" class="msger-input" placeholder="Enter your message...">
      <button type="submit" class="msger-send-btn" id="send-btn" aria-label="send Data"  (click)="send()">Send</button>
    </form>
  </section>
</div>





<div class="btn btn-raised chat-circle hidemobileview" style="    z-index: 1000;" (click)=" openchat() "
  *ngIf="chathide == false">
  <div class="chat-overlay"></div>
  <i style="    font-size: 25px;    color: white;" class="fa fa-commenting-o" aria-hidden="true"></i>
</div>



<div class="chat1-circle-chat1 mobilehide" *ngIf="IdChat == 1">
  <section class="msger1" style="float: inline-end;padding: 0;">
    <header class="msger-header">
      <div class="msger-header-title">
        <i class="fas fa-comment-alt"></i> Customer Support
      </div>
      <div class="msger-header-options" (click)=" closechat() ">
        <span><i class="fa fa-times" aria-hidden="true"></i></span>
      </div>
    </header>

    <main class="msger-chat">
      <div class="msg left-msg">
        <!-- <div class="msg-img" style="background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg)"></div> -->

        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">Customer Care</div>
            <div class="msg-info-time">12:45</div>
          </div>

          <div class="msg-text">
            Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
          </div>
        </div>


      </div>



      <!-- <div class="msg right-msg">
        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">Sajad</div>
            <div class="msg-info-time">12:46</div>
          </div>

          <div class="msg-text">
            You can change your name in JS section!
          </div>
        </div>
      </div> -->

      <!-- chatttttttttttt--------dataaaaaaaaaaaaaaaaaaaaa -->
      <div *ngFor="let data of DataChat">


        <div class="msg right-msg" style="margin-top: 10px;">
          <div class="msg-bubble">
            <div class="msg-info">
              <div class="msg-info-name">{{CusName}}</div>
              <div class="msg-info-time">{{data.sendTime}}</div>
            </div>
            <div class="msg-text">
              {{data.sender}}
            </div>
          </div>
        </div>

        <div class="msg left-msg" style="margin-top: 10px;">
          <div class="msg-bubble">
            <div class="msg-info">
              <div class="msg-info-name">Customer Care</div>
              <div class="msg-info-time">{{data.receiveTime}}</div>
            </div>
            <div class="msg-text">
              {{data.receiver}}
            </div>
          </div>
        </div>

      </div>

    </main>

    <form class="msger-inputarea">
      <input type="text" class="msger-input" placeholder="Enter your message...">
      <button type="submit" class="msger-send-btn" id="send-btn" aria-label="send Data"  (click)="send()">Send</button>
    </form>
  </section>
</div>
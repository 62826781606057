import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {

  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo

  public today: number = Date.now();
  public products: Product[] = [];
  public collapse: boolean = true;
  public Category: any[] = [];
  someCondition: any

  public fbcakelist = {
    message: 'Order Fresh Cream Cake online and get them Delivered Free. Serving in Bangalore, Chennai, Tiruvallur, Kancheepuram, Coimbatore, Madurai and Hyderabad.',
    address: 'HEAD OFFICE 60, RGM Complex, OMR, Karapakkam, Chennai-600097',
    phone: '90034 32888',
    email: 'Admin@Fbcakes.Com'
  };


  constructor(public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);


    this.productService.Categories.subscribe(Cat => {
      const data = Cat["data"]
      this.Category = data
      this.someCondition = this.Category[0].id
      // console.log(this.someCondition)
    });

  }

  selectid(item) {
    // console.log(item)
    this.someCondition = item

  }


  ngOnInit(): void {
  }



}

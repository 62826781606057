import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { MyService } from '../../services/myservice';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public Category: any[] = [];
  someCondition: Number = 0
  customerid: number
  constructor(public productService: ProductService, private route: ActivatedRoute, private myService: MyService, private router: Router,) {

    this.route.queryParams.subscribe(params => {
      const catid = params.category ? params.category : '0-test';

      this.category = parseInt(catid.split('-')[0], 10) || 0;
    })


    if (this.category == this.someCondition) {
      //  console.log('Category ID matches someCondition:', this.someCondition);
    } else {
      this.someCondition = this.category;
      //  console.log('someCondition updated to:', this.someCondition);
    }
    //  console.log(this.someCondition)

  }

  catid: number = 0

  selectid(item) {
    this.someCondition = item
    this.myService._catID.next(item);
  }

  category: number = 0

  ngOnInit(): void {
    this.getcategories()
    this.myService._catID.subscribe(data => {
      this.someCondition = data
      this.myService.PageNo.next(1);
    })

    this.route.queryParams.subscribe(params => {
      const catid = params.category ? params.category : '0-test';
      this.category = parseInt(catid.split('-')[0], 10) || 0;
    })


    if (this.category == this.someCondition) {
      // console.log('Category ID matches someCondition:', this.someCondition);
    } else {
      this.someCondition = this.category;
      // console.log('someCondition updated to:', this.someCondition);
    }
    // console.log(this.someCondition)
  }

  get filterbyCategory() {
    const category = [...new Set(this.Category.map(cat => cat.name))]
    return category
  }


  getcategories() {

    this.productService.Categories.subscribe(Cat => {
      const data = Cat["data"]
      this.Category = data
      // this.someCondition = this.Category[0].id
      if (this.someCondition == 0) {
        this.myService._catID.next(this.Category[0].id);
      }
    });

  }

}

<header class="sticky" [ngClass]="class" *ngIf="isHeaderVisible" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our Fbcakes</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 90034 32888</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end hidevalue" *ngIf="localStorage.getItem('denglu') == '1'">
          <ul class="header-dropdown">
            <li class="compare">
              <!-- <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a> -->
            </li>
            <!-- <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li> -->
            <li class="onhover-dropdown mobile-account" style="right: 7%;" (click)="clearchat()">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <br> <span class="hidevalue" *ngIf="this.name!=''"
                style="font-style:initial; font-size: small; text-align: center; font-weight: bold; color: orangered;">Hello
                {{name}}</span>
              <ul class="onhover-show-div" style="top: 63px;background-color: #f8f9fa;">
                <li (click)="loginheaderhide()">

                  <a data-lng="en" type="button" *ngIf="!localStorage.getItem('denglu')">
                    Login
                  </a>
                </li>
                <li (click)="logout()">
                  <a data-lng="es" *ngIf="localStorage.getItem('denglu') == '1'">
                    Logout
                  </a>
                </li>

                <li (click)="yourOrders(2)">

                  <a data-lng="en" type="button">
                    <span [ngClass]="{'custom-color':HeadId == 2 }">Your Order</span>
                  </a>
                </li>
                <li (click)="yourwishlist(3)">
                  <a data-lng="es">
                    <span [ngClass]="{'custom-color':HeadId == 3 }">Your Wish List</span>
                  </a>
                </li>
                <li (click)="yourAccount(1)">
                  <a data-lng="es">
                    <span [ngClass]="{'custom-color':HeadId == 1 }">Your Account</span>
                  </a>
                </li>

              </ul>
            </li>
          </ul>
        </div>

        <div class="col-lg-6 text-end systemview" *ngIf="localStorage.getItem('denglu') == '1'">
          <ul class="header-dropdown">

            <li class="onhover-dropdown mobile-account" style="right: 7%;" (click)="clearchat()">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <br> <span class="hidevalue" *ngIf="this.name!=''"
                style="font-style:initial; font-size: small; text-align: center; font-weight: bold; color: orangered;">Hello
                {{name}}</span>
              <ul class="onhover-show-div" style="background-color: #f8f9fa;" *ngIf="closehover == true">
                <li (click)="loginheaderhide()">

                  <a data-lng="en" type="button" *ngIf="!localStorage.getItem('denglu')">
                    Login
                  </a>
                </li>
                <li (click)="logout()">
                  <a data-lng="es" *ngIf="localStorage.getItem('denglu') == '1'">
                    Logout
                  </a>
                </li>

                <li (click)="yourOrders(2)">

                  <a data-lng="en" type="button">
                    <span [ngClass]="{'custom-color':HeadId == 2 }">Your Order</span>
                  </a>
                </li>
                <li (click)="yourwishlist(3)">
                  <a data-lng="es">
                    <span [ngClass]="{'custom-color':HeadId == 3 ,}">Your Wish List</span>
                  </a>
                </li>
                <li (click)="yourAccount(1)">
                  <a data-lng="es">
                    <span [ngClass]="{'custom-color':HeadId == 1 }">Your Account</span>
                  </a>
                </li>

              </ul>
            </li>
          </ul>
        </div>

        <div class="col-lg-6 text-end" *ngIf="!localStorage.getItem('denglu')">
          <ul class="header-dropdown">
            <li class="compare">
              <!-- <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a> -->
            </li>
            <!-- <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li> -->
            <li class="onhover-dropdown mobile-account" style="right: 7%;">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <!-- <br> <span class="hidevalue" *ngIf="this.name!=''"
                style="font-style:initial; font-size: small; text-align: center; font-weight: bold; color: orangered;">Hello
                {{name}}</span> -->
              <ul class="onhover-show-div">
                <li (click)="loginheaderhide()">

                  <a data-lng="en" type="button" *ngIf="!localStorage.getItem('denglu')">
                    Login
                  </a>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="home/fashion" id="headerlogo">
                <img [src]="footer[0]?.logo || header" class="img-fluid">
              </a>
            </div>
          </div>
          <div class="hidevalue col-3">

            <form class="form-inline search-form">
              <div style="position: relative; width: 100%;"> <!-- Ensures container alignment -->
                <span class="iInput2" style="position: absolute; top: 50%; transform: translateY(-50%); left: 10px;">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>

                <ng-template #rt let-r="result" let-t="term" class="dropdown-item"
                  style="display: flex; align-items: center; padding: 5px;">
                  <img [src]="r['imgUrl']" class="me-1" style="width: 30px;" />
                  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>

                <div class="mb-3 row">
                  <div class="col">
                    <input id="typeahead-template" placeholder="Search for cakes..." type="text"
                      style="padding: 10px 10px 10px 45px; width: 100%;" class="form-control input-number"
                      [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter"
                      (selectItem)="selectedItem($event.item)" [ngModelOptions]="{ standalone: true }" />
                  </div>
                </div>
              </div>
            </form>


          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings [CountCart]="CountCart"></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="mobile-cart-content row" *ngIf="strval1 < 3">
      <form class="form-inline search-form " style="padding-bottom: 5px;">
        <div>
          <span class="iInput">
            <i class="fa fa-search" aria-hidden="true"></i>
          </span>
          <ng-template #rt let-r="result" let-t="term">
            <img [src]=" r['imgUrl']" class="me-1" style="width: 16px" />
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
          </ng-template>

          <div class="mb-3 row">
            <div class="col">

              <input id="typeahead-template" type="text" placeholder="Search for cakes..."
                class="form-control col input-number" [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt"
                [inputFormatter]="formatter" (selectItem)="selectedItem($event.item)"
                [ngModelOptions]="{standalone: true}" />
            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</header>
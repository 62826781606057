import { Component, OnInit, Input, HostListener } from '@angular/core'; // Import Angular core modules and decorators
import { HomepageModule } from '../../../shared/classes/product'; // Import HomepageModule class
import { ProductService } from '../../../shared/services/product.service'; // Import ProductService
import { Router } from '@angular/router'; // Import Router for navigation
import { ToastrService } from 'ngx-toastr'; // Import ToastrService for notifications
import { ExternalService } from '../../services/external.service'; // Import ExternalService for external utility functions
import { OperatorFunction, Observable, map } from 'rxjs'; // Import RxJS utilities
import { debounceTime } from 'rxjs'; // Import debounceTime for search optimization
import { MyService } from '../../services/myservice'; // Import MyService for managing shared data

@Component({
  selector: 'app-header-one', // Selector for the component
  templateUrl: './header-one.component.html', // Template path
  styleUrls: ['./header-one.component.scss'] // Stylesheet path
})
export class HeaderOneComponent implements OnInit {
  @Input() class: string; // Input property for CSS class
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Input property for theme logo with default value
  @Input() topbar: boolean = true; // Input property for top bar visibility
  @Input() sticky: boolean = false; // Input property for sticky header
  @Input() Livelogo: string = 'https://biz1ps.azurewebsites.net/temp/audio/logo.png'; // Live logo URL
  @Input() footer: any = []; // Footer data input property
  result: any[] = []; // Array to store results
  public stick: boolean = false; // Boolean to manage sticky header state
  public collections = []; // Array to store collections
  public HomePage: HomepageModule[] = []; // Array to store homepage modules
  @Input() logoImg: string; // Input property for logo image
  public footer1 = []; // Array for footer content
  public CountCart; // Variable to store cart count
  public Ccount; // Variable to store cart count from localStorage
  showHeader: boolean = true; // Boolean to control header visibility
  public AllSearchProd; // Variable to store all search products
  public header; // Variable to store header data
  public Cdata: any = []; // Array for cart data
  public LiveCartCount; // Variable to store live cart count
  public dataurl = '/shop/collection/left/sidebar'; // Default URL for data
  public clearsearch; // Variable for cleared search state
  public strval1: number = 0; // Variable to store page ID from service

  constructor(
    public productService: ProductService, // Dependency injection of ProductService
    private router: Router, // Dependency injection of Router
    private toastrService: ToastrService, // Dependency injection of ToastrService
    private externalservice: ExternalService, // Dependency injection of ExternalService
    private myService: MyService // Dependency injection of MyService
  ) {
    if (this.router.url == '/shop/accounts') { // Check if current URL is '/shop/accounts'
      this.HeadId = 1; // Set HeadId to 1
    }

    // Subscribe to HearderId observable and update HeadId
    this.myService.HearderId.subscribe(data => {
      this.HeadId = data;
    });

    // Initialize cart count based on localStorage value
    if (localStorage.getItem('denglu') === '1') {
      this.Ccount = localStorage.getItem('myApp_CartCount');
      this.CountCart = parseInt(this.Ccount, 10) || 0;
    } else {
      this.CountCart = 0; // Default cart count
    }

    // Fetch header data and cart count if the URL contains 'shop'
    if (this.router.url.split('/')[1] === 'shop') {
      this.productService.Headerget.subscribe(response => {
        const head = response["header"];
        this.header = head[0]?.logo; // Extract logo from header response
        this.Cdata = response['cccount']; // Extract cart count data
        this.LiveCartCount = this.Cdata[0]?.cart_Count; // Set live cart count
      });
    }

    // Store clearsearch value from localStorage
    const storedValue = localStorage.getItem('mypg');
    this.clearsearch = parseInt(storedValue, 10) || 0;

    // Fetch all products and store in AllSearchProd
    this.productService.getAllProducts.subscribe(Cat => {
      const data = Cat["products"];
      this.AllSearchProd = data;
    });

    // Manage search input data from localStorage
    const data = localStorage.getItem('myApp_Search');
    let data1 = data || null;
    if (data1 !== null) {
      data1 = data.replace(/"/g, ''); // Remove quotes from string
    }
    this.model = data1 || ''; // Set model value
  }

  model: string = null; // Initialize model as null

  // Function to filter search results with debounce
  search: OperatorFunction<string, readonly { name; imgUrl }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200), // Wait for 200ms after last keystroke
      map((term) =>
        term === ''
          ? [] // Return empty array if term is empty
          : this.AllSearchProd.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10), // Filter products by name
      ),
    );

  // Function to format search results
  formatter = (x: { name: string }) => x.name || this.model;

  // Function to clear chat and reset hover state
  clearchat() {
    this.myService.ChatId.next(0); // Reset ChatId
    this.closehover = true; // Set hover state to true
  }

  closehover: boolean = false; // Hover state variable

  // Function to hide login header and navigate to login page
  loginheaderhide() {
    this.externalservice.getShowHeader(); // Retrieve current header visibility
    this.externalservice.setShowHeader(false); // Set header visibility to false
    this.router.navigate(['/pages/login']); // Navigate to login page
    this.externalservice.getShowHeader(); // Retrieve header visibility again
  }

  // Getter to check if header is visible
  get isHeaderVisible(): boolean {
    return this.externalservice.getShowHeader();
  }

  // Function to handle item selection
  selectedItem(item) {
    this.router.navigateByUrl('/shop/product/left/sidebar/' + item.id + '/' + item.name); // Navigate to product page
    localStorage.setItem("myApp_Search", JSON.stringify(item.name)); // Store selected item name
  }

  name = ""; // Variable to store name
  HeadId: number = 0; // Variable to store header ID

  ngOnInit(): void {
    // Subscribe to HearderId and update HeadId
    this.myService.HearderId.subscribe(data => {
      this.HeadId = data;
    });

    // Set HeadId to 1 if URL is '/shop/accounts'
    if (this.router.url == '/shop/accounts') {
      this.HeadId = 1;
    }

    // Subscribe to _pageId and update strval1
    this.myService._pageId.subscribe(data => {
      this.strval1 = data;
    });

    // Set name from localStorage if available
    if (localStorage.getItem('name')) {
      this.name = localStorage.getItem('name');
    }

    // Manage header visibility based on URL
    if (this.router.url === '/pages/login') {
      this.externalservice.setShowHeader(false);
    } else {
      this.externalservice.setShowHeader(true);
    }
  }

  // HostListener to handle scroll events
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0; // Get scroll position
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true; // Set sticky header state to true
    } else {
      this.stick = false; // Set sticky header state to false
    }
  }

  localStorage: Storage = window.localStorage; // Access localStorage

  denglu = 0; // Variable for login state

  // Logout function
  logout() {
    localStorage.removeItem('customerid'); // Remove customer ID from localStorage
    localStorage.removeItem('denglu'); // Remove login state from localStorage
    localStorage.removeItem('productId'); // Remove product ID
    localStorage.removeItem('prdname'); // Remove product name
    localStorage.removeItem('myApp_CartCount'); // Remove cart count
    localStorage.removeItem('name'); // Remove user name
    localStorage.removeItem('Chat'); // Remove chat data
    localStorage.removeItem('fav'); // Remove favorites
    localStorage.setItem('mypg', '5'); // Set page to 5
    this.myService._pageId.next(5); // Update _pageId observable
    this.router.navigateByUrl('home/fashion'); // Navigate to home page
    if (this.router.url == 'home/fashion') {
      location.reload(); // Reload page
    }
    // Show notification based on login state
    if (localStorage.getItem('denglu')) {
      this.toastrService.success('Login', ' Login Successfully!');
    } else {
      this.toastrService.success('LogOut', ' LogOut Successfully!');
    }
  }

  // Navigate to orders page
  yourOrders(item) {
    this.router.navigate(['/shop/order/management/']); // Navigate to order management
    this.myService.HearderId.next(item); // Update HearderId observable
    this.closehover = false; // Reset hover state
  }

  // Navigate to account page
  yourAccount(item) {
    this.router.navigate(['/shop/accounts/']); // Navigate to accounts page
    this.myService.HearderId.next(item); // Update HearderId observable
    this.closehover = false; // Reset hover state
  }

  // Navigate to wishlist page
  yourwishlist(item) {
    this.router.navigate(['/shop/wishlist/']); // Navigate to wishlist page
    this.myService.HearderId.next(item); // Update HearderId observable
    this.closehover = false; // Reset hover state
  }

  // Check if a result is active
  isActive(result): boolean {
    return result.name === 'Result 1'; // Example condition to check active state
  }
}

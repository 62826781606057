<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="page-title">
          <h2 *ngIf="footer1.length == 0">{{ title }}</h2>
        </div>

      </div>
      <div class="col-sm-4 text-center">
        <a routerLink="home/fashion" id="headerlogo">
          <img [src]="footer1[0]?.logo " class="img-fluid">
        </a>
      </div>
      <div class="col-sm-4">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">

            <li class="breadcrumb-item">
              <a [routerLink]="'/home/fashion'" [routerLinkActive]="'active'" aria-current="page" class="nav-link">
                Home
              </a>

            </li>

            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->